* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: rgb(163, 163, 163);

  &:hover {
    color: rgb(255, 42, 0);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(106, 112, 114);
  overflow-x: hidden;
}

svg {
  width: 1em;
  height: 1em;
  fill: inherit;
}

::selection {
  color: #fff;
  background-color: #828a8c;
}

.section {
  padding: 6rem 0;
}

// Hero

.hero {
  position: relative;
  background: url("../images/hero_smart.jpg") no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #222;

  @media screen and (min-width: 72em) {
    background: url("../images/hero.jpg");
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 120%;
    background-color: #828a8c;
    opacity: 0.15;
  }

  &::before {
    top: 0;
    left: 10%;
  }

  &::after {
    top: 0;
    right: 10%;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 1rem;
    @media screen and (min-width: 48em) {
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  &__text {
    max-width: 480px;
    @media screen and (min-width: 48em) {
      max-width: 680px;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.25;
      color: rgb(160, 169, 172);
      margin-bottom: 2rem;
      @media screen and (min-width: 48em) {
        font-size: 1.75rem;
        line-height: 1.25;
        margin-bottom: 3rem;
      }
    }
  }

  .subheading {
    color: rgb(160, 169, 172);
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    @media screen and (min-width: 48em) {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
  }

  &__heading {
    font-size: 3.25rem;
    line-height: 0.9;
    color: white;
    margin-bottom: 1.5rem;
    @media screen and (min-width: 36em) {
      font-size: 4.25rem;
    }
    @media screen and (min-width: 48em) {
      font-size: 6.25rem;
      line-height: 0.9;
      margin-bottom: 2.5rem;
    }
  }

  &__cta {
    backface-visibility: hidden;
  }
}

// Header

.header {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &__container {
    max-width: 100%;
    padding: 0 1rem;
    margin: 0 auto;

    @media screen and (min-width: 48em) {
      padding: 0 3rem;
    }
  }

  &__logo {
    width: 70px;
    transform: scale(1);
    transition: 0.25s ease-in-out;
    animation: 1s pop-in;
    animation-iteration-count: 2;

    @media screen and (min-width: 48em) {
      width: 80px;
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  &__social {
    position: absolute;
    right: 2%;
    bottom: 1rem;

    @media screen and (min-width: 48em) {
      width: 80px;
      bottom: 4rem;
    }
  }

  .section-links {
    .icon {
      width: 0.75em;
      height: 0.75em;
      transform: translateY(4px);
      transition: 0.25s ease-in-out;
    }

    a {
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff;
      fill: #fff;
      transition: 0.25s ease-in-out;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        color: #a0a9ac;
        fill: #a0a9ac;

        .icon {
          transform: translateY(10px);
        }
      }
    }
  }
}

// Nav

.nav {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  display: inline-block;
  white-space: nowrap;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 1.85rem;
  font-weight: 600;
  border: 0;
  background: rgb(224, 38, 30);
  background: linear-gradient(
    215deg,
    rgba(248, 81, 73, 1) 0%,
    rgba(224, 38, 30, 1) 100%
  );
  border-radius: 0px;
  transform: scale(1);
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.1);
    border-radius: 3px;
    color: #fff;
  }
}

.section-intro {
  text-align: center;

  img {
    width: 280px;
    margin-bottom: 2rem;

    @media screen and (min-width: 48em) {
      width: 380px;
    }
  }
  &__container {
    max-width: 740px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.25;
    @media screen and (min-width: 42em) {
      font-size: 1.75rem;
      text-align: center;
    }
  }
}

#map {
  box-shadow: 0 60px 100px 20px rgba(0, 0, 0, 0.1);
  background-color: #f5f8f8;
}

.section-address {
  position: relative;

  &__container {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 48em) {
      flex-direction: row;
    }

    div:first-child {
      @media screen and (min-width: 48em) {
        flex-direction: row;
        max-width: 72%;
      }
    }
  }

  .map-img {
    order: -1;
    min-width: 28%;
    height: 300px;
    background-image: url(/images/gym-comp-smart.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    @media screen and (min-width: 48em) {
      order: 1;
      height: 600px;
      border-radius: 0 12px 12px 0;
      background-image: url(/images/gym-comp.jpg);
    }
    @media screen and (min-width: 72em) {
      flex-direction: row;
      max-width: 72%;
    }
  }
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

// FOOTER

.footer {
  padding: 9rem 0 3rem;

  &__logo {
    margin-bottom: 0.5rem;
  }

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: 36em) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__unit-1 {
    padding-right: 1.5rem;
  }

  &__copyright {
    font-size: 1rem;
    margin-bottom: 0.375rem;
  }

  &__address {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  &__contact-links {
    .icon {
      margin-right: 0.5rem;
    }

    a {
      font-weight: 600;
      white-space: nowrap;
      fill: #a0a9ac;
      display: inline-flex;
      align-items: center;
      position: relative;
      left: -1rem;
      padding: 0.25rem 1rem;

      @media screen and (min-width: 36em) {
        border-radius: 20px;
      }

      &:hover {
        @media screen and (min-width: 36em) {
          background-color: rgba(0, 0, 0, 0.055);
        }
      }
    }
  }

  .social-icons {
    @media screen and (max-width: 36em) {
      background: none;
      padding: 0;
      margin-top: 1.5rem;
    }
    @media screen and (min-width: 36em) {
      align-self: flex-end;
    }
  }

  a {
    transition: 0.15s ease-in-out;
  }
}

.social-icons {
  // Dim palette
  $dim-first: #a0a9ac;
  $dim-second: #828a8c;

  padding: 0.25rem 1rem;
  margin: 0;
  list-style: none;
  display: flex;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.055);

  a {
    display: inline-flex;
    transition: 0.25s ease-in-out;
  }

  li {
    display: inline-flex;
    &:not(:last-child) {
      margin-right: 0.25em;
    }
  }

  &--sm {
    font-size: 1.65rem;
  }

  &--m {
    font-size: 2.25rem;
  }

  &--dim {
    fill: $dim-first;
    a {
      &:hover {
        fill: $dim-second;
      }
    }
  }
}

// BRANCHES

.branches {
  padding-top: 3rem;
  @media screen and (min-width: 48em) {
    padding-top: 6rem;
  }

  h2 {
    font-size: 2.65rem;
    color: #222;
    margin-bottom: 1.5rem;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 20px;
      height: 1px;
      background-color: rgb(130, 138, 140);
    }

    &::before {
      top: -40px;
      transform-origin: center;
      transform: rotate(45deg) translateX(-50%);
    }

    &::after {
      top: -54px;
      transform-origin: center;
      transform: rotate(-45deg) translateX(-50%);
    }
  }
  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: 48em) {
      display: flex;
      justify-content: space-between;
    }
    div {
      &:not(:last-child) {
        margin-bottom: 7rem;
      }
      @media screen and (min-width: 48em) {
        max-width: 46%;
        margin-bottom: 0;
      }
    }
  }
}

.list {
  list-style: none;
  display: flex;
  align-items: center;

  a {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
  }

  li {
    fill: #828a8c;
    &:not(:last-child) {
      margin-left: 3rem;
    }
  }
}

.df {
  display: inline-flex;
}

.icon {
  display: inline-flex;
}

.mr-sm {
  margin-right: 0.5rem;
}
.mr-m {
  margin-right: 1.5rem;
}

.mr-l {
  margin-right: 2rem;
}

.mt-m {
  margin-top: 1.5rem;
}

.team {
  color: #a0a9ac;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 1.5rem;
}

.contact-txt {
  display: block;
  font-weight: 600;
  margin-right: 1rem;

  @media screen and (min-width: 36em) {
    display: inline-block;
  }
}

@keyframes pop-in {
  84% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  86% {
    opacity: 0;
  }
  87% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  89% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
  }
  97% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
