* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #a3a3a3;
}

a:hover {
  color: #ff2a00;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #6a7072;
  overflow-x: hidden;
}

svg {
  width: 1em;
  height: 1em;
  fill: inherit;
}

::selection {
  color: #fff;
  background-color: #828a8c;
}

.section {
  padding: 6rem 0;
}

.hero {
  position: relative;
  background: url("hero_smart.75cdb6e8.jpg") no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #222;
}

@media screen and (min-width: 72em) {
  .hero {
    background: url("hero.b43a877c.jpg");
  }
}

.hero::before, .hero::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 120%;
  background-color: #828a8c;
  opacity: 0.15;
}

.hero::before {
  top: 0;
  left: 10%;
}

.hero::after {
  top: 0;
  right: 10%;
}

.hero__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem;
}

@media screen and (min-width: 48em) {
  .hero__container {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.hero__text {
  max-width: 480px;
}

@media screen and (min-width: 48em) {
  .hero__text {
    max-width: 680px;
  }
}

.hero__text p {
  font-size: 1.25rem;
  line-height: 1.25;
  color: #a0a9ac;
  margin-bottom: 2rem;
}

@media screen and (min-width: 48em) {
  .hero__text p {
    font-size: 1.75rem;
    line-height: 1.25;
    margin-bottom: 3rem;
  }
}

.hero .subheading {
  color: #a0a9ac;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}

@media screen and (min-width: 48em) {
  .hero .subheading {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
}

.hero__heading {
  font-size: 3.25rem;
  line-height: 0.9;
  color: white;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 36em) {
  .hero__heading {
    font-size: 4.25rem;
  }
}

@media screen and (min-width: 48em) {
  .hero__heading {
    font-size: 6.25rem;
    line-height: 0.9;
    margin-bottom: 2.5rem;
  }
}

.hero__cta {
  backface-visibility: hidden;
}

.header {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header__container {
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

@media screen and (min-width: 48em) {
  .header__container {
    padding: 0 3rem;
  }
}

.header__logo {
  width: 70px;
  transform: scale(1);
  transition: 0.25s ease-in-out;
  animation: 1s pop-in;
  animation-iteration-count: 2;
}

@media screen and (min-width: 48em) {
  .header__logo {
    width: 80px;
  }
}

.header__logo:hover {
  transform: scale(1.2);
}

.header__social {
  position: absolute;
  right: 2%;
  bottom: 1rem;
}

@media screen and (min-width: 48em) {
  .header__social {
    width: 80px;
    bottom: 4rem;
  }
}

.header .section-links .icon {
  width: 0.75em;
  height: 0.75em;
  transform: translateY(4px);
  transition: 0.25s ease-in-out;
}

.header .section-links a {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  fill: #fff;
  transition: 0.25s ease-in-out;
}

.header .section-links a:not(:last-child) {
  margin-right: 1rem;
}

.header .section-links a:hover {
  color: #a0a9ac;
  fill: #a0a9ac;
}

.header .section-links a:hover .icon {
  transform: translateY(10px);
}

.nav {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  display: inline-block;
  white-space: nowrap;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 1.85rem;
  font-weight: 600;
  border: 0;
  background: #e0261e;
  background: linear-gradient(215deg, #f85149 0%, #e0261e 100%);
  border-radius: 0px;
  transform: scale(1);
  transition: 0.25s ease-in-out;
}

.btn:hover {
  transform: scale(1.1);
  border-radius: 3px;
  color: #fff;
}

.section-intro {
  text-align: center;
}

.section-intro img {
  width: 280px;
  margin-bottom: 2rem;
}

@media screen and (min-width: 48em) {
  .section-intro img {
    width: 380px;
  }
}

.section-intro__container {
  max-width: 740px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-intro p {
  font-size: 1.25rem;
  line-height: 1.25;
}

@media screen and (min-width: 42em) {
  .section-intro p {
    font-size: 1.75rem;
    text-align: center;
  }
}

#map {
  box-shadow: 0 60px 100px 20px rgba(0, 0, 0, 0.1);
  background-color: #f5f8f8;
}

.section-address {
  position: relative;
}

.section-address__container {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 48em) {
  .section-address__container {
    flex-direction: row;
  }
}

@media screen and (min-width: 48em) {
  .section-address__container div:first-child {
    flex-direction: row;
    max-width: 72%;
  }
}

.section-address .map-img {
  order: -1;
  min-width: 28%;
  height: 300px;
  background-image: url(gym-comp-smart.dc54370e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

@media screen and (min-width: 48em) {
  .section-address .map-img {
    order: 1;
    height: 600px;
    border-radius: 0 12px 12px 0;
    background-image: url(gym-comp.caf6bda1.jpg);
  }
}

@media screen and (min-width: 72em) {
  .section-address .map-img {
    flex-direction: row;
    max-width: 72%;
  }
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.footer {
  padding: 9rem 0 3rem;
}

.footer__logo {
  margin-bottom: 0.5rem;
}

.footer__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media screen and (min-width: 36em) {
  .footer__container {
    display: flex;
    justify-content: space-between;
  }
}

.footer__unit-1 {
  padding-right: 1.5rem;
}

.footer__copyright {
  font-size: 1rem;
  margin-bottom: 0.375rem;
}

.footer__address {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.footer__contact-links .icon {
  margin-right: 0.5rem;
}

.footer__contact-links a {
  font-weight: 600;
  white-space: nowrap;
  fill: #a0a9ac;
  display: inline-flex;
  align-items: center;
  position: relative;
  left: -1rem;
  padding: 0.25rem 1rem;
}

@media screen and (min-width: 36em) {
  .footer__contact-links a {
    border-radius: 20px;
  }
}

@media screen and (min-width: 36em) {
  .footer__contact-links a:hover {
    background-color: rgba(0, 0, 0, 0.055);
  }
}

@media screen and (max-width: 36em) {
  .footer .social-icons {
    background: none;
    padding: 0;
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 36em) {
  .footer .social-icons {
    align-self: flex-end;
  }
}

.footer a {
  transition: 0.15s ease-in-out;
}

.social-icons {
  padding: 0.25rem 1rem;
  margin: 0;
  list-style: none;
  display: flex;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.055);
}

.social-icons a {
  display: inline-flex;
  transition: 0.25s ease-in-out;
}

.social-icons li {
  display: inline-flex;
}

.social-icons li:not(:last-child) {
  margin-right: 0.25em;
}

.social-icons--sm {
  font-size: 1.65rem;
}

.social-icons--m {
  font-size: 2.25rem;
}

.social-icons--dim {
  fill: #a0a9ac;
}

.social-icons--dim a:hover {
  fill: #828a8c;
}

.branches {
  padding-top: 3rem;
}

@media screen and (min-width: 48em) {
  .branches {
    padding-top: 6rem;
  }
}

.branches h2 {
  font-size: 2.65rem;
  color: #222;
  margin-bottom: 1.5rem;
  position: relative;
}

.branches h2::before, .branches h2::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 20px;
  height: 1px;
  background-color: #828a8c;
}

.branches h2::before {
  top: -40px;
  transform-origin: center;
  transform: rotate(45deg) translateX(-50%);
}

.branches h2::after {
  top: -54px;
  transform-origin: center;
  transform: rotate(-45deg) translateX(-50%);
}

.branches__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media screen and (min-width: 48em) {
  .branches__container {
    display: flex;
    justify-content: space-between;
  }
}

.branches__container div:not(:last-child) {
  margin-bottom: 7rem;
}

@media screen and (min-width: 48em) {
  .branches__container div {
    max-width: 46%;
    margin-bottom: 0;
  }
}

.list {
  list-style: none;
  display: flex;
  align-items: center;
}

.list a {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
}

.list li {
  fill: #828a8c;
}

.list li:not(:last-child) {
  margin-left: 3rem;
}

.df {
  display: inline-flex;
}

.icon {
  display: inline-flex;
}

.mr-sm {
  margin-right: 0.5rem;
}

.mr-m {
  margin-right: 1.5rem;
}

.mr-l {
  margin-right: 2rem;
}

.mt-m {
  margin-top: 1.5rem;
}

.team {
  color: #a0a9ac;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 1.5rem;
}

.contact-txt {
  display: block;
  font-weight: 600;
  margin-right: 1rem;
}

@media screen and (min-width: 36em) {
  .contact-txt {
    display: inline-block;
  }
}

@keyframes pop-in {
  84% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  86% {
    opacity: 0;
  }
  87% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  89% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
  }
  97% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.d8371773.css.map */
